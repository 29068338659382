<template>
  <div class="balaceBox">
    <TitleHeader msg="我的积分" class="headerWraper"></TitleHeader>
    <el-row class="numWraper">
      <el-col :span="6">
        <p class="balaceTit">可用的积分</p>
        <span class="balaceNum">{{  PointsNum>0 ? PointsNum : 0 }}</span>
      </el-col>
    </el-row>
    <p class="progressBar"></p>
    <el-row class="fromWraper">
      <el-row >
         <Nav :obj="obj" @clickIndex="changePropsIndex"></Nav>
      </el-row>
    </el-row>
    <el-row>
      <ul class="ulBox">
        <li>
          <el-date-picker
              format="yyyy 年 MM 月"
              :picker-options="pickerBeginDateBefore"
              clear-icon=""
              prefix-icon="el-icon-arrow-down"
              v-model="dateValue"
              size="small"
              type="month"
              @change="ChangeDate"
            >
          </el-date-picker>
        </li>
        <li>交易类型</li>
        <li>交易积分</li>
        <li>支付单号</li>
        <li>当前积分</li>
      </ul>        <!--  label="交易时间"          label="交易金额"-->
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        :show-header="false"
        class="wraper"
        style="width: 100%;"
      >
        <el-table-column class="text-center" prop="createTime"  width="220" align="left" >
           
        </el-table-column>
        <el-table-column
          class="text-center"
          prop="typeStr"
          width="180"
           label="交易名称"
           align="center" 
        >
        </el-table-column>

        <el-table-column
          class="text-center"
          prop="points"
          label="交易积分"
          width="180"
          align="center" 
        >
        </el-table-column>
        <el-table-column
          class="text-center"
          prop="id"
          label="积分id"
          width="180"
          align="center" 
        >
        </el-table-column>
        <el-table-column class="text-center" label="当前积分" prop="presentPoints" align="center" >
        </el-table-column>
      </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="false"
            prev-text="上一页"
            next-text="下一页"
          @current-change="handleCurrentChange"
            :current-page="current"
          :page-size="size"
          :total="total">
        </el-pagination>
    </el-row>
  </div>
</template>
<style lang="less">
.balaceBox {
  .nav {
    li {
        float: left;
        padding: 10px;
        color:#666666 ;
        font-size: 14px;
        cursor: pointer;
         &:hover{
                color: #ff3333;
            }
    }
    .active {
        color:#FF3333;
      }
  }
  .el-date-editor{
    .el-input__prefix{
      right: -20px;
    }
  }
  .el-input__inner{
    border: none;
    background: #f6f6f6;
  }
  .el-table .cell{
    font-size: 12px;
    color: #333;
  }




     .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #ff3333; 
        color: #fff;
    }
/* 上一页，下一页样式 */
.el-pagination button, .el-pagination span:not([class*=suffix]){
    background: #fff;
    color: #666;
     padding: 4px;
     box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li{
    background: #fff;
    color: #666;
    padding: px;
    box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover{
    color:#ff3333; 
    font-size: 14px;
}
}
</style>

<style lang="less" scoped>
@import "~style/index.less";
    // @media screen and ( max-width:1280px ) {
    //    .ulBox{
    //    li{
    //      width: 120px !important;
    //    }
    //    li:first-child{
    //     width: 200px !important;
    //   }
    //    li:last-child{
    //     width:calc( 100% - 560px );
    //   }
       
    //  }
    // }
 .el-pagination {
     padding-top:20px;
     text-align: right
     }
.balaceBox {
  .progressBar{
    height: 20px;
    margin-left:-20px;
    width: 100%;
    background: #f6f6f6;
    padding: 0 20px;
  }
  .fromWraper{
     padding:20px 0;
  }
  .ulBox{
       background: #F6F6F6;
       height: 38px;
       line-height: 30px;
       li{
         float: left;
         text-align: center;
         color:#666666;
         width:180px;
        line-height: 38px;
        font-size: 12px;
       }
       li:first-child{
        width: 220px;
      }
       li:last-child{
        width:calc( 100% - 760px );
      }
       
     }
  .numWraper {
    padding: 36px;
    display: flex;
    align-items: center;
    .balaceTit {
      font-size: 16px;
      color: #333;
    }
    .btn {
      width: 136px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      padding: 0;
      border-radius: 5px;
      &.RechargeBtn {
        background: #ff3333;
        color: #fff;
        margin-bottom: 16px;
          cursor: pointer;
           
      }
      &.TransferAccountBtn {
        background: #ff7a45;
        color: #fff;
          cursor: pointer;
         
      }
    }
    .balaceNum {
      font-weight: bold;
      padding: 4px 0;
      line-height: 65px;
      font-size:30px;
    }
    ul li {
      float: left;
      padding-left: 20px;
      cursor: pointer;
    }
    .active {
      color: red;
    }
    .amountInfo {
      span {
        color: red;
        font-weight: 600;
        padding: 4px;
        font-size: 16px;
      }
      .rechargeInfo {
        padding-left: 20px;
      }
    }
  }

}
</style>

<script>
import {pointsLogList ,userPointsNum } from 'api/member.js';
const TitleHeader = () => import("components/common/TitleHeader.vue");
const Nav = ()=>import("components/common/Nav.vue");
export default {
  name: "Balance",
  data() {
    return {
      total:0,
       current:1,//当前页
       size:10,//每页数量
      //日期选择框不能超过当前时间
      time:'',
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      //选项卡默认选择第一项
      currentIndex: 0,
      //加载loading条
      loading: false,
      PointsNum: 0,
      PointsId:'',
      //日期选择框默认是当前月
      dateValue: new Date(),
     
      //导航栏信息
      obj:{
            content:[
              {
                type:1,
                name:"全部记录"
              },
              {
                type:2,
                name:"获取积分记录"
              },
              {
                type:3,
                name:"消费积分记录"
              }
            ],
            currentIndex:0
      },
      tableData: [
     
      ],
    };
  },
  methods: {
      
    ChangeDate(){
       let currentTime = new Date(this.dateValue), year = currentTime.getFullYear(), month = currentTime.getMonth()+1
       if(month>9){
        this.time=year+'-'+month+'-'

       }else{
         this.time=year+'-0'+month+'-'
       }
       this.current=1
       this.PointsDetailsFun(this.obj.currentIndex+1);
    },
     changePropsIndex(params){
        this.obj.currentIndex = params;
        this.current=1;
        this.PointsDetailsFun(params+1)
      },
      
      handleCurrentChange(val) {
        this.current=val
          this.PointsDetailsFun();
      },
      // 积分明细接口
      PointsDetailsFun(params){
         let datas={};
        if(params){
            if(params==1){
                // 全部
                datas={
                    current:this.current,//当前页
                    size:this.size,//每页数量
                    time:this.time,
                    userId:this.PointsId
                }
            }else if(params==2){
                // 获取记录
                datas={
                    gtPoints:1,
                    current:this.current,//当前页
                    size:this.size,//每页数量
                    time:this.time,
                    userId:this.PointsId
                }
            }else{
                // 消费
                datas={
                    ltPoints:1,
                    current:this.current,//当前页
                    size:this.size,//每页数量
                    time:this.time,
                    userId:this.PointsId
                }
            }
           
        }else{
            datas={
               current:this.current,//当前页
              size:this.size,//每页数量
               time:this.time,
               userId:this.PointsId
            }
        }
         // 显示loading   
     this.$loading({ fullscreen: true ,background:'#ffffff45'})
        pointsLogList(datas).then(res=>{
              this.tableData=res.data.data.records
              // 初始获取所有数据数量
              this.total=res.data.data.total;
               // 关闭loading
              this.$loading().close();
          })
      }
   
  },
  created() {
    let currentTime = new Date(this.dateValue), year = currentTime.getFullYear(), month = currentTime.getMonth()+1
    if(month>9){
    this.time=year+'-'+month+'-'
    }else{
      this.time=year+'-0'+month+'-'
    }
     // 获取积分数量  和积分人员编号
      userPointsNum().then(res=>{
        this.PointsNum=res.data.data.points
        this.PointsId=res.data.data.userId
        this.PointsDetailsFun();

      })
     
   
  },
  mounted() {},
  components: {
    TitleHeader,
      Nav,
  },
  computed: {},
};
</script>
